<template>
  <div>
    <a-button type="primary" @click="showModal"> 上传bin文件 </a-button>
    <a-modal
      :visible="visible"
      title="上传bin文件"
      @cancel="handleCancel"
      @create="handleCreate"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <a-form-item label="name">
          <a-input
            placeholder="文件名称"
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please input your type!' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="uploadBin">
          <a-input type="file" @change="Upload" />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit"> 确认 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import axios from "axios";
export default {
  data() {
    return {
      file: "",
      mypath: "",
      formData: "",
      visible: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  methods: {
    Upload(event) {
      this.flie = event.target.files[0];
      console.log({ data: this.file });
    },
    showModal() {
      this.visible = !this.visible;
    },
    // 取消填写表单
    handleCancel() {
      this.visible = false;
    },
    //  确认表单
    handleCreate() {
      this.visible = false;
    },
    handleSubmit(e) {
      let this_1 = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //let a = eval(values.status.toLowerCase())
          // console.log(typeof(a))
          // console.log('Received values of form: ', values); https://api.rslly.top/api/v2/DealBin
          this_1.mypath = "";
          let formData = new FormData();
          formData.append("file", this.flie);
          console.log(formData.get("file"));
          const config = {
            params:{
              name:values.name
            },
            headers: {
              "Content-Type":
                "multipart/form-data;boundary=" + new Date().getTime(),
                Authorization:localStorage.getItem('Authorization') 
            },
          };
          axios
            .post("https://api.rslly.top/api/v2/DealBin", formData, config)
            .then((response) => {
              this_1.visible = false;
              console.log(response.data.code);
              if (response.data.errorCode == 200) {
                this_1.$message.success("上传成功");
              } else {
                this_1.$message.error("上传失败");
              }
            });
        }
      });
    },
    handleSelectChange(value) {
      //  console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
  },
};
</script>