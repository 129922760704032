<template>
  <div>
    <a-spin :spinning="spinning" tip="客官请稍等，小的马上就来">
      <a-table
        :pagination="pagination"
        :columns="columns"
        :data-source="data"
        :scroll="scroll"
      >
        <a slot-scope="text">{{ text }}</a>
        <span slot="customTitle">ID</span>
        <span slot="action" slot-scope="text, record">
          <!-- <a>Invite 一 {{ record.name }}</a>
      <a-divider type="vertical" />
      <a>Delete</a>
      <a-divider type="vertical" /> -->
          <!--a @click="() => edit(record)" class="ant-dropdown-link">禁止 </a>
          <a-divider type="vertical" /-->
          <a @click="() => edit(record)" class="ant-dropdown-link">烧录 </a>
          <a-divider type="vertical" />
          <a @click="() => deletedevices(record)" class="ant-dropdown-link"
            >删除
          </a>
          <a-modal
            :visible="visible"
            title="烧录控制台"
            @cancel="handleCancel"
            @create="handleCreate"
          >
            <a-form
              :form="form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
              @submit="handleSubmit"
            >
              <a-form-item label="name">
                <a-input
                  placeholder="name"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        { required: true, message: 'Please input your number!' },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item label="deviceName">
                <a-input
                  placeholder="deviceName"
                  v-decorator="[
                    'deviceName',
                    {
                      rules: [
                        { required: true, message: 'Please input your number!' },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              
              <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit"> 确认 </a-button>
              </a-form-item>
            </a-form>
          </a-modal>
        </span>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
const columns = [
  {
    title: "ID",
    dataIndex: "myid",
    key: "myid",
  },
  {
    title: "文件名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "文件路径",
    dataIndex: "path",
    key: "path",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

// let data = [
//   {
//     key: '1',
//     id: 'John Brown',
//     name: "湿度传感器",
//     time: '2021-04-20',
//     state: '在线',
//   },

// ];
// let listobject={
//         key: "",
//         id: "",
//         name: "",
//         time: 0,
//         state: true,
//       }

import axios from "axios";
export default {
  //在模板中渲染成html后调用，通常初始化页面完成后在对html的dom进行操作

  name: "ota",
  props: ["list"],
  data() {
     //为变量赋值
     return {
      //分页栏
      //分页栏
          pagination: {
              pageSize: 10,
          },           
          //表格数据是否正在加载
          loading: false,
          //表格表头筛选条件
          filteredInfo: null,
          //表格表头排序条件
          sortedInfo: null,
          //表格选中行
          selectedRowKeys: [],
          //表格横向与纵向宽度
          //设置表格锁定表头和列时必须设置此配置
          scroll: {
              x: 1000,
              y: "calc(100vh - 450px)",
          },
      visible: false,
      mypath: "",
      spinning: false,
      data: [],
      columns,
      mykey: 1,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },

  //   hardWareID: 888
  // id: "033324a5-9d0e-4125-97a9-25cfcc9b353b"
  // name: "空调"
  // status: true
  // time: 1619362148
  // type: "product"
  watch: {
    //监听一个值的变化，然后执行对应函数

    list(n, o) {
      //遍历数组
      this.spinning = false;
      let this_1 = this;
      this.spinning = false;
      this.data = n.map((item) => {
        return {
          myid: item.id,
          name: item.name,
          path: item.path,
        };
      });
    },
  },
  created() {
    ///////
    this.spinning = true;
  },
  methods: {
    //编写js函数
    edit(record) {
      this.visible = true;
      console.log("播放");
    },
    handleCancel() {
      this.visible = false;
    },
    handleCreate() {
      this.visible = false;
    },
    handleSubmit(e) {
      let this_1 = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //let a = eval(values.status.toLowerCase())
          // console.log(typeof(a))
          // console.log('Received values of form: ', values); https://api.rslly.top/api/v2/DealBin
        
          const config = {
            params:{
              name:values.name,
              deviceName:values.deviceName

            },
            headers: {
                Authorization:localStorage.getItem('Authorization') 
            },
          };
          axios
            .get("https://api.rslly.top/api/v2/ota",  config)
            .then((response) => {
              this_1.visible = false;
              console.log(response.data.code);
              if (response.data.errorCode == 200) {
                this_1.$message.success("烧录成功");
              } else {
                this_1.$message.error("烧录失败");
              }
            });
        }
      });
    },
    deletedevices(recode) {
      let _this = this;
      _this.mypath = window.g.pro_data_delete;
      axios
        .delete("https://api.rslly.top/api/v2/DealBin", {
          headers: {
            Authorization: localStorage.getItem("Authorization"),
          },
          params: {
            id: recode.myid,
          },
        })
        .then((response) => {
          // this.devises = response.data.data
          // console.log(typeof(response.data))
          //console.log(response)
          if (response.data.errorCode === 200) {
            _this.$message.success("删除文件成功，三秒后更新列表");
          }  else {
            _this.$message.error("删除协议失败，三秒后更新列表");
          }
        });
    },
    formatTime(time) {
      let unixtime = time;
      let unixTimestamp = new Date(unixtime * 1000);
      let Y = unixTimestamp.getFullYear();
      let M =
        unixTimestamp.getMonth() + 1 > 10
          ? unixTimestamp.getMonth() + 1
          : "0" + (unixTimestamp.getMonth() + 1);
      let D =
        unixTimestamp.getDate() > 10
          ? unixTimestamp.getDate()
          : "0" + unixTimestamp.getDate();
      let toDay = Y + "-" + M + "-" + D;
      return toDay;
    },
  },
};
</script>