var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.spinning,"tip":"客官请稍等，小的马上就来"}},[_c('a-table',{attrs:{"pagination":_vm.pagination,"columns":_vm.columns,"data-source":_vm.data,"scroll":_vm.scroll},scopedSlots:_vm._u([{key:"default",fn:function(text){return _c('a',{},[_vm._v(_vm._s(text))])}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function () { return _vm.edit(record); }}},[_vm._v("烧录 ")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticClass:"ant-dropdown-link",on:{"click":function () { return _vm.deletedevices(record); }}},[_vm._v("删除 ")]),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"烧录控制台"},on:{"cancel":_vm.handleCancel,"create":_vm.handleCreate}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    rules: [
                      { required: true, message: 'Please input your number!' } ],
                  } ]),expression:"[\n                  'name',\n                  {\n                    rules: [\n                      { required: true, message: 'Please input your number!' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"name"}})],1),_c('a-form-item',{attrs:{"label":"deviceName"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'deviceName',
                  {
                    rules: [
                      { required: true, message: 'Please input your number!' } ],
                  } ]),expression:"[\n                  'deviceName',\n                  {\n                    rules: [\n                      { required: true, message: 'Please input your number!' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"deviceName"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 确认 ")])],1)],1)],1)],1)}}])},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_vm._v("ID")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }